import React from 'react'
import { useAtom } from 'jotai';
import { Controller, useForm } from "react-hook-form"
import { RadioGroup as RadioCard } from '@headlessui/react'
import { useState, useEffect } from 'react';
import { designCountAtom, selectedServicesAtom, socialMediaPaymentFreq, websiteDevelopmentAtom, designAtom, socialMediaManagementAtom, ecommerceIndianAtom } from '../utils/atoms';
import { SELECTED_SERVICES } from '../utils/queries';
import { useQuery } from '@apollo/client';
import FloatingLabelInput from '../components/ui/inputs/FloatingLabelInput';
import IconlessRadio from '../components/ui/inputs/IconlessRadio';
import { FormControl } from '@mui/base';
import FormLabel from '@mui/material/FormLabel';
import { FormControlLabel, FormHelperText, Switch, Radio } from '@mui/material';
import TextField from '@mui/material/TextField';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { MuiChipsInput } from 'mui-chips-input'
import Autocomplete from '@mui/material/Autocomplete';
import { selectedDesignAtom } from '../utils/atoms';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { pink } from '@mui/material/colors';
import { Facebook, X, Instagram, LinkedIn } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Alert from '@mui/material/Alert';




const technologies = [
    {
        name: 'Wordpress',
        imgPath: '/images/wordpress.png',
        description: "It's suitable for various types of websites, including blogs, portfolios, and small business websites.",
    },
    {
        name: 'Shopify',
        imgPath: '/images/shopify.png',
        description: "An e-commerce platform that provides a complete solution for selling products online.",
    },
    {
        name: 'Customized Solution',
        imgPath: '/images/code.png',
        description: "Tailored solutions built from scratch to meet your specific requirements.",
    },
]

const designDeliverables = [
    { title: 'Logo', name: 'logo' },
    { title: 'Visitng Card', name: 'visiting_card' },
    { title: 'Letter Head', name: 'letter_head' },
    { title: 'Envelope', name: 'envelope' },
    { title: 'Box Packaging', name: 'box_packaging' },
    { title: "Label", name: 'label' },
    { title: 'Pouch', name: 'pouch' },
    { title: '3D Mockup (Video)', name: '3d_mockup_video' },
    { title: 'Flyer', name: 'flyer' },
    { title: 'Brochure', name: 'brochure' },
];


const Quote = () => {
    const [selectedTech, setSelectedTech] = useState(technologies[0])
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm();
    const [selectedServices] = useAtom(selectedServicesAtom);
    const [paymentFreq, setPaymentFreq] = useAtom(socialMediaPaymentFreq);

    const [webDevService, setWebDevService] = useAtom(websiteDevelopmentAtom);
    const [ecommerceData, setEcommerceData] = useAtom(ecommerceIndianAtom);
    // const [designItems, setDesignItems] = useAtom(designAtom);
    const [socialMediaPlan, setSocialMediaPlan] = useAtom(socialMediaManagementAtom);

    const [design, setDesign] = useAtom(designAtom);
    const [selectedDesign, setSelectedDesign] = useState([]);
    const [designCount, setDesignCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(designCount);
        console.log(selectedDesign);
    }, [designCount, selectedDesign]);

    const handleSelectDesign = (event, newValue) => {
        setSelectedDesign(newValue);
    };

    const handleDesignCountChange = (name, count) => {
        setDesignCount(prev => ({ ...prev, [name]: count }));
    };

    let trueKeys = Object.entries(selectedServices).filter(([key, value]) => value).map(([key, value]) => key);
    const { loading, error, data } = useQuery(SELECTED_SERVICES, {
        variables: {
            ids: trueKeys
        }
    });

    if (errors) {
        console.error(errors); // Log errors for debugging
        // Display error messages to the user
    }

    const onSubmit = (data) => {

        console.log(data);
        if (data.website) {
            data.website.platform = selectedTech;
            setWebDevService(data.website);
        }
        if (designingService) {
            console.log(data.design)
            data.design = designCount
            setDesign(data.design);
        }

        if (ecommerceIndianService) {
            data.ecommerce_indian.platforms = selectedPlatform
            console.log(data)
            setEcommerceData(data.ecommerce_indian);
            // setDesign(data.design);
        }
        navigate('/preview');
    };

    const websiteDevelopmentService = data?.selectedServices.find(
        service => service.slug === 'website_development'
    );

    const seoService = data?.selectedServices.find(
        service => service.slug === 'seo'
    )

    const designingService = data?.selectedServices.find(
        service => service.slug === 'designing'
    )

    const socialMediaManangementService = data?.selectedServices.find(
        service => service.slug === 'social_media_management'
    )

    const ecommerceIndianService = data?.selectedServices.find(
        service => service.slug === 'ecommerce_indian'
    )

    if (data) {
        console.log(ecommerceIndianService);
    }

    const isEcommerce = watch('website.isEcommerce');
    const isProfessionalMail = watch('website.isProfessionalMail');
    const isWebsiteContent = watch('website.content');

    const [keywords, setKeywords] = React.useState([])

    const handleKeywordsChange = (newKeywords) => {
        setKeywords(newKeywords)
    }

    const handleSelectedPlatform = (item) => {
        if (selectedPlatform.includes(item)) {
            setSelectedPlatform(selectedPlatform.filter(i => i !== item));
        } else {
            if (selectedPlatform.length == 4) {
                console.log(selectedPlatform.length);
                toast("Only 4 platforms can be selected, if you need to select more, please contact our sales team !");
            } else {
                setSelectedPlatform([...selectedPlatform, item]);
            }
        }
    };

    useEffect(() => {
        console.log(selectedPlatform)
    }, [selectedPlatform])


    return (
        <>
            <div className='w-full h-full flex flex-col max-w-6xl mx-auto gap-y-4 justify-center items-center'>
                <div className='header flex flex-col gap-y-2 mt-12'>
                    <div className='font-semibold'>Step 2 of 4</div>
                    <h1 className=''>Please answer all the questions</h1>
                    <p>Fill all the details to get correct price estimation.</p>
                </div>
                <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-y-8">
                    {/* Website Form */}
                    {
                        websiteDevelopmentService &&
                        <>
                            <div className='w-full flex-col border-gray-400 h-full p-10 bg-white shadow-md rounded-xl flex justify-center items-start'>
                                <h3 className='text-3xl font-bold mb-6 text-left'>Website Details</h3>
                                <div className='flex flex-col w-full items-start gap-y-6'>
                                    <div className="flex flex-col w-full items-start relative mb-2">
                                        <Controller
                                            name={"website.website_name"}
                                            control={control}
                                            rules={{ required: websiteDevelopmentService ? true : false }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                                formState,
                                            }) => (
                                                <TextField
                                                    helperText={error ? error.message : null}
                                                    size="large"
                                                    error={!!error}
                                                    onChange={onChange}
                                                    value={value}
                                                    fullWidth
                                                    label={"Website Name"}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="flex flex-col w-full items-start relative mb-2">
                                        <RadioCard value={selectedTech} onChange={setSelectedTech} className="flex flex-col items-start w-full">
                                            <RadioCard.Label className="font-semibold text-xl mb-4">On which platform you want your website to be built upon?</RadioCard.Label>
                                            <div className="flex flex-row max-sm:flex-col gap-y-4 w-full justify-between gap-x-4">
                                                {technologies.map((item, index) => (
                                                    <RadioCard.Option
                                                        key={index}
                                                        value={item}
                                                        className={({ active, checked }) =>
                                                            `${active
                                                                ? ''
                                                                : ''
                                                            }
                  ${checked ? 'bg-gray-600 text-white' : 'bg-white'}
                  hover:scale-95 transition-all w-full flex flex-col items-start justify-start rounded-lg border-2 border-gray-200 p-4`
                                                        }
                                                    >
                                                        {({ active, checked }) => (
                                                            <>
                                                                <div className="flex w-full items-start justify-between">
                                                                    <div className="flex items-center">
                                                                        <div className=" text-left flex flex-col items-start justify-start">
                                                                            <img src={item.imgPath} />
                                                                            <RadioCard.Label
                                                                                as="p"
                                                                                className={`font-bold mb-2 mt-4 ${checked ? 'text-white' : 'text-gray-900'
                                                                                    }`}
                                                                            >
                                                                                {item.name}
                                                                            </RadioCard.Label>
                                                                            <RadioCard.Description
                                                                                as="span"
                                                                                className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'
                                                                                    }`}
                                                                            >
                                                                                <span>{item.description}</span>
                                                                            </RadioCard.Description>
                                                                        </div>
                                                                    </div>
                                                                    {checked && (
                                                                        <div className="shrink-0 text-white">
                                                                            <CheckIcon className="h-6 w-6" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </RadioCard.Option>
                                                ))}
                                            </div>
                                        </RadioCard>
                                    </div>
                                </div>
                                {
                                    selectedTech.name !== "Customized Solution" ?
                                        <>
                                            {
                                                (selectedTech.name === 'Wordpress' || selectedTech.name === 'Shopify') && <>
                                                    <div className='flex w-full flex-row max-sm:flex-col max-sm:items-start justify-between items-center mt-6'>
                                                        <label className='font-semibold text-xl mb-4 w-full text-left'>How many pages you want on your website?</label>
                                                        <Controller
                                                            name="website.pages"
                                                            control={control}
                                                            defaultValue="" // Set the default value here
                                                            rules={{
                                                                min: { value: 1, message: 'Minimum value is 1' },
                                                                required: websiteDevelopmentService ? true : false,
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    helperText={error}
                                                                    error={!!error}
                                                                    size="large"
                                                                    label="Pages"
                                                                    variant="outlined"
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 1,
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            {/* Wordpress Website Ecommerce */}
                                            <div className='flex flex-row w-full max-sm:flex-col justify-between items-start mt-6'>
                                                <span className='font-semibold text-xl text-left mb-4'>Do you plan on selling products on your site? *</span>
                                                <Controller
                                                    name="website.isEcommerce"
                                                    rules={{ required: websiteDevelopmentService ? true : false }}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            control={<Switch checked={field.value} onChange={field.onChange} />}
                                                            label={(isEcommerce || selectedTech.name === 'Shopify') ? 'Yes' : 'No'}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {
                                                isEcommerce &&
                                                <>
                                                    <div className='flex flex-row justify-between max-sm:flex-col max-sm:items-start items-center mt-6 w-full'>
                                                        <label className='font-semibold text-xl mb-4 w-full text-left'>How many products you want to sell online?</label>
                                                        <Controller
                                                            name="website.ecommerce.products"
                                                            control={control}
                                                            defaultValue="" // Set the default value here
                                                            rules={{
                                                                required: 'This field is required',
                                                                min: { value: 1, message: 'Minimum value is 1' },
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    helperText={error}
                                                                    error={!!error}
                                                                    size="large"
                                                                    label="No. Of Products"
                                                                    variant="outlined"
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 1,
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            {/* Website Content */}
                                            <div>
                                                <Controller
                                                    name="website.content"
                                                    control={control}
                                                    defaultValue="" // Set the default value here
                                                    render={({ field }) => (
                                                        <FormControl className='flex items-start flex-col mt-6'>
                                                            <label id="website_content" className="font-semibold text-xl mb-4 w-full text-left">How do you plan to add content to your website?</label>
                                                            <RadioGroup
                                                                aria-labelledby="website_content"
                                                                name="website.content"
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                            >
                                                                <FormControlLabel value="self" control={<Radio />} label="I will create and add content to all pages on my own" />
                                                                <FormControlLabel value="professional" control={<Radio />} label="I would like the pages in my website to be professionally designed" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )}
                                                />
                                            </div>

                                            {
                                                isWebsiteContent == 'professional' &&
                                                <>
                                                    <div>
                                                        <Controller
                                                            name="website.content_type"
                                                            control={control}
                                                            defaultValue="" // Set the default value here
                                                            render={({ field }) => (
                                                                <FormControl className='flex items-start flex-col mt-6'>
                                                                    <label id="website_content_type" className="font-semibold text-xl mb-4 w-full text-left">What type of content you prefer for your website?</label>
                                                                    <RadioGroup
                                                                        aria-labelledby="website_content_type"
                                                                        name="website.content_type"
                                                                        value={field.value}
                                                                        onChange={field.onChange}
                                                                    >
                                                                        <FormControlLabel value="ai" control={<Radio />} label="AI Generated Content" />
                                                                        <FormControlLabel value="human" control={<Radio />} label="Human Written Content" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            {/* Professional mails */}
                                            <div className='flex flex-row max-sm:flex-col w-full justify-between items-start mt-6'>
                                                <div className='flex flex-col items-start'>
                                                    <span className='font-semibold text-xl text-left'>Do you wish to have professional emails for your website?*</span>
                                                    <span className='text-sm font-normal text-gray-400'>e.g. - info@example.com</span>
                                                </div>
                                                <Controller
                                                    name="website.isProfessionalMail"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            control={<Switch checked={field.value} onChange={field.onChange} />}
                                                            label={isProfessionalMail ? 'Yes' : 'No'}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {
                                                isProfessionalMail &&
                                                <>
                                                    <div className='flex flex-row justify-between items-center mt-6 w-full max-sm:flex-col max-sm:items-start'>
                                                        <label className='font-semibold text-xl mb-4 w-full text-left'>How many emails do you want?</label>
                                                        <Controller
                                                            name="website.mail.count"
                                                            control={control}
                                                            defaultValue="" // Set the default value here
                                                            rules={{
                                                                required: 'This field is required',
                                                                min: { value: 1, message: 'Minimum value is 1' },
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    helperText={error}
                                                                    error={!!error}
                                                                    size="large"
                                                                    label="No. Of Emails"
                                                                    variant="outlined"
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 1,
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            <div className='flex flex-row justify-between items-center mt-6 w-full max-sm:items-start max-sm:flex-col'>
                                                <label className='font-semibold text-xl mb-4 w-full text-left'>How many landing pages do you want, apart from home page?</label>
                                                <Controller
                                                    name="website.landing_page.count"
                                                    control={control}
                                                    defaultValue="" // Set the default value here
                                                    rules={{
                                                        required: 'This field is required',
                                                        min: { value: 1, message: 'Minimum value is 1' },
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            helperText={error}
                                                            error={!!error}
                                                            size="large"
                                                            label="Landing Pages"
                                                            variant="outlined"
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 0,
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='w-full'>
                                                <Alert sx={{ fontSize: '20px' }} severity="info">Our team will call you regarding customized website solution. Thank you !</Alert>
                                            </div>

                                        </>
                                }
                            </div>
                        </>
                    }
                    {/* SEO Form */}
                    {/* {
                        seoService &&
                        <>
                            <div className='w-full flex-col border-gray-400 h-full p-10 bg-white shadow-md rounded-xl flex justify-center items-start'>
                                <h3 className='text-3xl font-bold mb-6 text-left'>SEO Details</h3>
                                <div className='flex flex-col w-full items-start gap-y-6'>
                                    {
                                        !websiteDevelopmentService &&
                                        <>
                                            <div className=" w-full items-start relative mb-2">
                                                <Controller
                                                    name={"website.website_name"}
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value },
                                                        fieldState: { error },
                                                        formState,
                                                    }) => (
                                                        <TextField
                                                            helperText={error ? error.message : null}
                                                            size="large"
                                                            error={!!error}
                                                            onChange={onChange}
                                                            value={value}
                                                            fullWidth
                                                            label={"Website Name"}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </>
                                    }

                                    <label className='font-semibold text-lg '>Please add keywords here:</label>
                                    <Controller
                                        name="seo.keywords"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <MuiChipsInput
                                                {...field}
                                                hideClearAll
                                                error={fieldState.invalid}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </>
                    } */}

                    {/* Designing Form */}
                    {
                        designingService &&
                        <>
                            <div className='w-full flex-col border-gray-400 h-full p-10 bg-white shadow-md rounded-xl flex justify-center items-start'>
                                <h3 className='text-3xl font-bold mb-6 text-left'>Designing Details</h3>

                                <label className='font-semibold text-lg mb-4'>Please add designs here:</label>
                                <Controller
                                    name="designing"
                                    control={control}
                                    // onChange={(event, newValue) => handleSelectDesign(event, newValue)}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            multiple
                                            className='w-full'
                                            id="tags-outlined"
                                            onChange={(event, newValue) => handleSelectDesign(event, newValue)} // Update the form value when Autocomplete value changes
                                            options={designDeliverables}
                                            getOptionLabel={(option) => option.title}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose Items For Designing"
                                                    placeholder="Designs"
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <div className='grid grid-cols-2 gap-y-6 gap-x-20 w-full mt-6 max-sm:grid-cols-1'>
                                    {
                                        selectedDesign?.map((item, index) => (
                                            <>

                                                <div key={index} className='w-full flex items-center justify-between flex-row max-sm:flex-col max-sm:items-start'>
                                                    <label className='font-semibold text-xl mb-4 text-left capitalize'>{item.name}</label>
                                                    <Controller
                                                        name={`${item.name}-count`}
                                                        control={control}
                                                        rules={{
                                                            required: false,
                                                            min: { value: 1, message: 'Minimum value is 1' },
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                size="large"
                                                                label={`No. of ${item.name}`}
                                                                variant="outlined"
                                                                type="number"
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 1,
                                                                    },
                                                                }}
                                                                onChange={(event) => handleDesignCountChange(item.name, event.target.value)}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                    }

                    {/* Social Media Management */}
                    {
                        socialMediaManangementService &&
                        <>
                            <div className='w-full flex-col border-gray-400 h-full p-10 bg-white shadow-md rounded-xl flex justify-center items-start'>
                                <section class="bg-white">
                                    <div class="container mx-auto">
                                        <div class="sm:flex sm:items-center max-sm:flex-col  sm:justify-between flex items-center justify-between">
                                            <div className='text-left max-sm:text-center'>
                                                <h2 class="text-3xl font-bold text-gray-800">Social Media Management Plans</h2>
                                                <p class="mt-4 text-gray-500">No Contracts. No surprise fees.</p>
                                            </div>

                                            <div class="overflow-hidden p-0.5 mt-6 border rounded-lg">
                                                <div class="sm:-mx-0.5 flex">
                                                    {
                                                        ['month', 'quarter', 'annual'].map((item, index) =>
                                                            <>
                                                                <button onClick={() => { setPaymentFreq(item) }} className={`focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 rounded-lg capitalize ${item == paymentFreq && 'text-white bg-[#D21A46]'}`}>{item}ly</button>
                                                            </>)
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid gap-6 mt-16 -mx-6 sm:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                                            {
                                                socialMediaManangementService.attributes.map((item, index) => (
                                                    <>
                                                        <div key={index} onClick={() => { setSocialMediaPlan(item.id) }} class={`${socialMediaPlan === item.id && 'border-2 border-[#D21A46]'} px-6 py-4 transition-colors duration-200 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 group`}>
                                                            <p class="text-lg font-medium text-gray-800 group-hover:text-gray-100">{item.plan_name}</p>

                                                            <h4 class="mt-2 text-4xl font-semibold text-gray-800 group-hover:text-gray-100">₹ {item.attributes[15].value[paymentFreq] ?? item.attributes[15].value['month']} <span class="text-base font-normal text-gray-600 group-hover:text-gray-200 ">/ {paymentFreq}</span></h4>
                                                            <p class="mt-4 text-gray-500 group-hover:text-gray-300">For most businesses that want to optimaize web queries.</p>

                                                            <div class="mt-8 space-y-8">
                                                                {
                                                                    item.attributes.map((attribute, index) =>
                                                                        <>
                                                                            {
                                                                                attribute.name != 'Pricing' &&
                                                                                <>
                                                                                    <div class={`group-hover:text-gray-200 flex items-center font-semibold justify-center ${(attribute.value == 0 || attribute.value == false) && 'line-through'}`}>
                                                                                        {
                                                                                            (typeof (attribute.value) == 'boolean' && attribute.value === true) &&
                                                                                            <>
                                                                                                <CheckCircleIcon color="success" />&nbsp;
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            (typeof (attribute.value) == 'boolean' && attribute.value === false) &&
                                                                                            <>
                                                                                                <CancelIcon sx={{ color: pink[500] }} />&nbsp;
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            (typeof (attribute.value) == 'number') && <>{attribute.value}&nbsp;</>
                                                                                        }
                                                                                        {
                                                                                            <span>{attribute.name}</span>
                                                                                        }
                                                                                        {
                                                                                            (Array.isArray(attribute.value)) &&
                                                                                            <>
                                                                                                <div className='flex flex-row'>
                                                                                                    {
                                                                                                        attribute.value.map((icon, index) =>
                                                                                                            <>
                                                                                                                {
                                                                                                                    icon == 'Facebook' && <Facebook />
                                                                                                                }
                                                                                                                {
                                                                                                                    icon == 'Instagram' && <Instagram />
                                                                                                                }
                                                                                                                {
                                                                                                                    icon == 'Twitter' && <X />
                                                                                                                }
                                                                                                                {
                                                                                                                    icon == 'LinkedIn' && <LinkedIn />
                                                                                                                }
                                                                                                            </>)
                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                        }

                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </>)
                                                                }
                                                            </div>

                                                            <button class="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-[#D21A46] rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                                                                Choose plan
                                                            </button>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </>
                    }

                    {/* Ecommerce */}
                    {
                        ecommerceIndianService &&
                        <>
                            <div className='w-full flex-col border-gray-400 h-full p-10 bg-white shadow-md rounded-xl flex justify-center items-start'>
                                <h3 className='text-3xl font-bold mb-6 text-left'>Ecommerce (Indian) Details</h3>
                                <div className='flex flex-col w-full items-start gap-y-6'>
                                    <div className="flex flex-col w-full items-start relative mb-2">
                                        <Controller
                                            name={"ecommerce_indian.brand_name"}
                                            control={control}
                                            rules={{ required: ecommerceIndianService ? true : false }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                                formState,
                                            }) => (
                                                <TextField
                                                    helperText={error ? error.message : null}
                                                    size="large"
                                                    error={!!error}
                                                    onChange={onChange}
                                                    value={value}
                                                    fullWidth
                                                    label={"Brand Name"}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="flex flex-col w-full items-start relative mb-2">
                                        <div value={selectedPlatform} onChange={setSelectedPlatform} className="flex flex-col items-start w-full">
                                            <p className="font-semibold text-xl mb-4">On which platform you want to sell your products?</p>
                                            <div className="grid grid-cols-6 w-full justify-between gap-4 max-sm:grid-cols-2">
                                                {ecommerceIndianService.attributes[0].value.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        onClick={() => handleSelectedPlatform(item.slug)}
                                                        className={`hover:scale-95 transition-all w-full flex flex-col items-start justify-start rounded-lg border-2  p-4 ${selectedPlatform.includes(item.slug) ? 'border-[#D21A46]' : 'border-gray-200'}`}>
                                                        <div className="flex w-full items-start justify-between">
                                                            <div className="flex items-center">
                                                                <div className=" text-left flex flex-col items-start justify-start">
                                                                    <img src={item.imgPath} width="auto" className='h-[50px]' />
                                                                    <p as="p" className={`font-bold mb-2 mt-4 text-gray-900`}>
                                                                        {item.name}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {
                                                                selectedPlatform.includes(item.slug) &&
                                                                <div className="shrink-0 text-black">
                                                                    <CheckIcon className="h-6 w-6" />
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (selectedPlatform) && <>
                                        <div className='flex w-full flex-row justify-between items-center mt-6'>
                                            <label className='font-semibold text-xl mb-4 w-full text-left'>How many products you want to sell online?</label>
                                            <Controller
                                                name="ecommerce_indian.products"
                                                control={control}
                                                defaultValue="" // Set the default value here
                                                rules={{
                                                    min: { value: 1, message: 'Minimum value is 1' },
                                                    required: ecommerceIndianService ? true : false,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        helperText={error}
                                                        error={!!error}
                                                        size="large"
                                                        label="Products"
                                                        variant="outlined"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 1,
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </>
                                }

                                {/* Wordpress Website Ecommerce */}
                                <div className='flex flex-row w-full justify-between items-start mt-6'>
                                    <span className='font-semibold text-xl text-left mb-4'>Do you want A/C Setup &amp; Brand/Category Appproval Service ? *</span>
                                    <Controller
                                        name="ecommerce_indian.ac_setup"
                                        rules={{ required: ecommerceIndianService ? true : false }}
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={<Switch checked={field.value} onChange={field.onChange} />}
                                                label={watch('ecommerce_indian.ac_setup') ? 'Yes' : 'No'}
                                            />
                                        )}
                                    />
                                </div>

                                <div className='flex flex-row w-full justify-between items-start mt-6'>
                                    <span className='font-semibold text-xl text-left mb-4'>Do you want Account Management Service ? *</span>
                                    <Controller
                                        name="ecommerce_indian.ac_management_service"
                                        rules={{ required: ecommerceIndianService ? true : false }}
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={<Switch checked={field.value} onChange={field.onChange} />}
                                                label={watch('ecommerce_indian.ac_management_service') ? 'Yes' : 'No'}
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="ecommerce_indian.content"
                                        control={control}
                                        defaultValue="" // Set the default value here
                                        render={({ field }) => (
                                            <FormControl className='flex items-start flex-col mt-6'>
                                                <label id="ecommerce_content" className="font-semibold text-xl mb-4 w-full text-left">What type of content you prefer for your product listings?</label>
                                                <RadioGroup
                                                    aria-labelledby="ecommerce_content"
                                                    name="ecommerce_indian.content"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                >
                                                    <FormControlLabel value="ai" control={<Radio />} label="AI Generated Content" />
                                                    <FormControlLabel value="human" control={<Radio />} label="Human Written Content" />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                </div>
                                {/* Amazon Exclusive */}
                                {
                                    selectedPlatform.includes('amazon') &&
                                    <>
                                        <div className='flex flex-row w-full justify-between items-start mt-6'>
                                            <span className='font-semibold text-xl text-left mb-4'>Do you want Brand Story ? *</span>
                                            <Controller
                                                name="ecommerce_indian.amazon.brand_story"
                                                rules={{ required: ecommerceIndianService ? true : false }}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={<Switch checked={field.value} onChange={field.onChange} />}
                                                        label={watch('ecommerce_indian.amazon.brand_story') ? 'Yes' : 'No'}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className='flex flex-row w-full justify-between items-start mt-6'>
                                            <span className='font-semibold text-xl text-left mb-4'>Do you want Brand Store ? *</span>
                                            <Controller
                                                name="ecommerce_indian.amazon.brand_store"
                                                rules={{ required: ecommerceIndianService ? true : false }}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={<Switch checked={field.value} onChange={field.onChange} />}
                                                        label={watch('ecommerce_indian.amazon.brand_store') ? 'Yes' : 'No'}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className='flex flex-row w-full justify-between items-start mt-6'>
                                            <span className='font-semibold text-xl text-left mb-4'>Do you want A Plus Content ? *</span>
                                            <Controller
                                                name="ecommerce_indian.amazon.a_plus"
                                                rules={{ required: ecommerceIndianService ? true : false }}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={<Switch checked={field.value} onChange={field.onChange} />}
                                                        label={watch('ecommerce_indian.amazon.a_plus') ? 'Yes' : 'No'}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    // isEcommerce &&
                                    // <>
                                    //     <div className='flex flex-row justify-between items-center mt-6 w-full'>
                                    //         <label className='font-semibold text-xl mb-4 w-full text-left'>How many products you want to sell online?</label>
                                    //         <Controller
                                    //             name="website.ecommerce.products"
                                    //             control={control}
                                    //             defaultValue="" // Set the default value here
                                    //             rules={{
                                    //                 required: 'This field is required',
                                    //                 min: { value: 1, message: 'Minimum value is 1' },
                                    //             }}
                                    //             render={({ field }) => (
                                    //                 <TextField
                                    //                     {...field}
                                    //                     helperText={error}
                                    //                     error={!!error}
                                    //                     size="large"
                                    //                     label="No. Of Products"
                                    //                     variant="outlined"
                                    //                     type="number"
                                    //                     InputProps={{
                                    //                         inputProps: {
                                    //                             min: 1,
                                    //                         },
                                    //                     }}
                                    //                 />
                                    //             )}
                                    //         />
                                    //     </div>
                                    // </>
                                }

                                {/* Website Content */}
                                {/* <div>
                                    <Controller
                                        name="website.content"
                                        control={control}
                                        defaultValue="" // Set the default value here
                                        render={({ field }) => (
                                            <FormControl className='flex items-start flex-col mt-6'>
                                                <label id="website_content" className="font-semibold text-xl mb-4 w-full text-left">How do you plan to add content to your website?</label>
                                                <RadioGroup
                                                    aria-labelledby="website_content"
                                                    name="website.content"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                >
                                                    <FormControlLabel value="self" control={<Radio />} label="I will create and add content to all pages on my own" />
                                                    <FormControlLabel value="professional" control={<Radio />} label="I would like the pages in my website to be professionally designed" />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                </div> */}

                                {/* Professional mails */}
                                {/* <div className='flex flex-row w-full justify-between items-start mt-6'>
                                    <div className='flex flex-col items-start'>
                                        <span className='font-semibold text-xl text-left'>Do you wish to have professional emails for your website?*</span>
                                        <span className='text-sm font-normal text-gray-400'>e.g. - info@example.com</span>
                                    </div>
                                    <Controller
                                        name="website.isProfessionalMail"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={<Switch checked={field.value} onChange={field.onChange} />}
                                                label={isProfessionalMail ? 'Yes' : 'No'}
                                            />
                                        )}
                                    />
                                </div> */}
                                {
                                    // isProfessionalMail &&
                                    // <>
                                    //     <div className='flex flex-row justify-between items-center mt-6 w-full'>
                                    //         <label className='font-semibold text-xl mb-4 w-full text-left'>How many emails do you want?</label>
                                    //         <Controller
                                    //             name="website.mail.count"
                                    //             control={control}
                                    //             defaultValue="" // Set the default value here
                                    //             rules={{
                                    //                 required: 'This field is required',
                                    //                 min: { value: 1, message: 'Minimum value is 1' },
                                    //             }}
                                    //             render={({ field }) => (
                                    //                 <TextField
                                    //                     {...field}
                                    //                     helperText={error}
                                    //                     error={!!error}
                                    //                     size="large"
                                    //                     label="No. Of Emails"
                                    //                     variant="outlined"
                                    //                     type="number"
                                    //                     InputProps={{
                                    //                         inputProps: {
                                    //                             min: 1,
                                    //                         },
                                    //                     }}
                                    //                 />
                                    //             )}
                                    //         />
                                    //     </div>
                                    // </>
                                }

                                {/* <div className='flex flex-row justify-between items-center mt-6 w-full'>
                                    <label className='font-semibold text-xl mb-4 w-full text-left'>How many landing pages do you want, apart from home page?</label>
                                    <Controller
                                        name="website.landing_page.count"
                                        control={control}
                                        defaultValue="" // Set the default value here
                                        rules={{
                                            required: 'This field is required',
                                            min: { value: 1, message: 'Minimum value is 1' },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                helperText={error}
                                                error={!!error}
                                                size="large"
                                                label="Landing Pages"
                                                variant="outlined"
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0,
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </div> */}
                            </div>
                        </>
                    }

                    <div className='flex items-start justify-start mt-6'>
                        <button type="submit" className='bg-[#D21A46] text-lg py-2 px-4 rounded-lg text-white'>Submit</button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default Quote

function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}