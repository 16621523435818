import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { TextField, Button } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { ENQUIRY_DATA } from '../utils/mutations';
import { useAtom } from 'jotai';
import { finalServicesAtom } from '../utils/atoms';

const handlePdfDownload = (pdfData) => {
    const filename = pdfData.filename;
    const content = pdfData.content;
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = 'document.pdf'; // Set the desired filename
    link.click();
    URL.revokeObjectURL(blobUrl);
};

const Preview = () => {
    const { handleSubmit, control } = useForm();
    // const onSubmit = (data) => console.log(data);
    const [createEnquiry, { data, loading, error }] = useMutation(ENQUIRY_DATA);
    const [finalServiceData] = useAtom(finalServicesAtom);

    const onSubmit = (values) => {
        values.userData = finalServiceData;
        console.log(values)
        createEnquiry({ variables: { input: values } }).then((response) => {
            if (response.data) {
                handlePdfDownload(response.data.newEnquiry);
            }
        })
            .catch((err) => {
                console.error('Error creating enquiry:', err);
            });
    };

    return (
        <>
            <div className='w-full h-full flex flex-col max-w-6xl mx-auto gap-y-4 justify-center items-center'>
                <div className='header flex flex-col gap-y-2 mt-12'>
                    <div className='font-semibold'>Step 3 of 4</div>
                    <h1 className=''>Please fill your contact details.</h1>
                    <p>Fill all the details to get correct price estimation.</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                    <div className='grid grid-cols-2 justify-center items-center px-14 py-14 gap-6 gap-y-8'>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} label="Name" />}
                        />
                        <Controller
                            name="contact_number"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} type="tel" label="Contact Number" />}
                        />
                        <Controller
                            name="city"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} label="City" />}
                        />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} type="email" label="Email" />}
                        />

                    </div>
                    <Controller
                        name="salesID"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <TextField {...field} label="Sales Executive Name" />}
                    />
                    <Button type="submit">Submit</Button>
                </form>
            </div>
        </>
    )
}

export default Preview