import { gql } from "@apollo/client";

export const ALL_SERVICES = gql`
  query Services {
    services {
      id
      name
      description
    }
  }
`;

export const SELECTED_SERVICES = gql`
  query selectedServices($ids: [ID!]!) {
    selectedServices(ids: $ids) {
      name
      attributes
      slug
    }
  }
`;