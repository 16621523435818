import { gql, useMutation } from '@apollo/client';

// const CALCULATE_PRICING = gql`
//   mutation CalculatePricing($input: ServicesInput!) {
//     calculatePricing(input: $input) {
      
//     }
//   }
// `;

export const ENQUIRY_DATA = gql`
  mutation NewEnquiry($input: EnquiryInput!) {
    newEnquiry(input: $input) {
      filename
      content
    }
  }
`;

