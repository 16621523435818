import { useAtom, atom } from "jotai";
import { atomWithReducer, atomWithStorage } from "jotai/utils";

const initialState = [];

const finalServicesInitialState = {
  website_development: null,
  social_media_management: null,
  designing: null,
  ecommerce_indian: null,
};

export const selectedServicesAtom = atomWithStorage(
  "selectedServices",
  initialState
);

export const updateSelectedServicesAtom = atom(
    (get) => get(selectedServicesAtom),
    (get, set, serviceId) => {
      const currentSelected = get(selectedServicesAtom);
      set(selectedServicesAtom, {
        ...currentSelected,
        [serviceId]: !currentSelected[serviceId],
      });
    }
  );

export const finalServicesAtom = atomWithStorage('finalServicesAtom', finalServicesInitialState);

export const submitSelectedServicesAtom = atom(null, (get, set, values) => {
  set(selectedServicesAtom, values);
});

export const selectedDesignAtom = atomWithStorage("selectedDesignItems", initialState);

export const designCountAtom = atomWithStorage("designCountAtom", initialState);

// export const socialMediaPaymentFreq = atom(
//   // (get) => get(socialMediaManagementAtom)?.payment_frequency,
//   (get) => get(finalServicesAtom).social_media_management.payment_frequency,
//   (get, set, data) => {
//     const prev = get(finalServicesAtom);
//     return set(finalServicesAtom, { ...prev, social_media_management: {payment_frequency: data} });
//   }
// );

export const socialMediaPaymentFreq = atom(
  (get) => {
    if(get(finalServicesAtom).social_media_management) {
      return get(finalServicesAtom).social_media_management.payment_frequency
    } else {
      return 'month'
    }
  },
  (get, set, newPaymentFrequency) => {
    const prevServices = get(finalServicesAtom);
    const updatedSocialMediaManagement = {
      ...prevServices.social_media_management,
      payment_frequency: newPaymentFrequency
    };
    const updatedServices = {
      ...prevServices,
      social_media_management: updatedSocialMediaManagement
    };
    set(finalServicesAtom, updatedServices);
  }
);

export const socialMediaManagementAtom = atom(
  // (get) => get(finalServicesAtom).social_media_management.plan,
  (get) => {
    if(get(finalServicesAtom).social_media_management) {
      return get(finalServicesAtom).social_media_management.plan
    }
  },
  (get, set, newPlan) => {
    const prevServices = get(finalServicesAtom);
    const updatedSocialMediaManagement = {
      ...prevServices.social_media_management,
      plan: newPlan
    };
    const updatedServices = {
      ...prevServices,
      social_media_management: updatedSocialMediaManagement
    };
    set(finalServicesAtom, updatedServices);
  }
);
// export const socialMediaPaymentFreq = atom((get) => get(finalServicesAtom).social_media_management.payment_frequency);

// export const socialMediaManagementAtom = atom((get) => get(finalServicesAtom).social_media_management.plan);

export const websiteDevelopmentAtom = atom(
  (get) => get(finalServicesAtom).website_development,
  (get, set, data) => {
    const prev = get(finalServicesAtom);
    return set(finalServicesAtom, { ...prev, website_development: data });
  }
);

export const ecommerceIndianAtom = atom(
  (get) => get(finalServicesAtom).ecommerce_indian,
  (get, set, data) => {
    const prev = get(finalServicesAtom);
    return set(finalServicesAtom, { ...prev, ecommerce_indian: data });
  }
);


export const designAtom = atom(
  (get) => get(finalServicesAtom).designing,
  (get, set, data) => {
    const prev = get(finalServicesAtom);
    return set(finalServicesAtom, { ...prev, designing: data });
  }
);

// export const socialMediaManagementAtom = atom(
//   (get) => get(finalServicesAtom).social_media_management.plan,
//   (get, set, data) => {
//     const prev = get(finalServicesAtom);
//     return set(finalServicesAtom, { ...prev, social_media_management: {plan: data} });
//   }
// );


// export const selectedServicesAtom = atomWithReducer({}, (prevState, action) => {
//     switch (action.type) {
//       case 'TOGGLE_SERVICE':
//         return {
//           ...prevState,
//           [action.serviceId]: !prevState[action.serviceId],
//         };
//       default:
//         return prevState;
//     }
//   });
