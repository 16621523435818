import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Quote from "./pages/Quote";
import Preview from "./pages/Preview";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CallIcon from '@mui/icons-material/Call';function App() {
  return (
    <div className="App">
      <div className="flex flex-row justify-between items-center max-w-7xl w-full mx-auto py-4 px-4">
          <div className="">
            <img src={'https://brandchanakya.in/wp-content/uploads/2022/07/2.png'} className=" scale-90" alt="logo" />
          </div>
          <div>
            <a href="https://brandchanakya.in/contact-us/" className="bg-[#D21A46] group px-4 py-2 text-white font-semibold rounded-lg hover:translate-y-full transition-all hover:bg-white hover:text-black hover:shadow-xl">
              <span className="text-white md:hidden group-hover:text-black"><CallIcon fontSize="small" /></span>
              <span className="text-white hidden md:inline group-hover:text-black">Contact Us</span>
            </a>
          </div>
      </div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/preview" element={<Preview />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
